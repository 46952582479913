<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img src="/images/strategy-studio.svg" alt="Strategy studio"/>
    </div>

    <!-- Sidebar Navigation Menu -->
	<a-tabs v-model:activeKey="navMode.activeKey">
		<a-tab-pane key="1" tab="Explorer">
			<a-menu theme="light" mode="inline">
		      <a-menu-item v-if="nav.curr_model_id != 0" key="m2">
		        <router-link :to="products_link()"><DatabaseOutlined />Products</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0" key="m3">
		        <router-link :to="competitors_link()"><DatabaseOutlined />Competitors</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0" key="m7">
		        <router-link :to="economic_obj_link()"><DatabaseOutlined />Economic objectives</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0" key="m9">
		        <router-link :to="market_attractiveness_link()"
		          ><DatabaseOutlined />Market Attractiveness Factors</router-link
		        >
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0" key="m10">
		        <router-link :to="competitive_strength_link()"
		          ><DatabaseOutlined />Competitive Strength Factor</router-link
		        >
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0 && nav.name == 'Criteron'" key="m11">
		        <router-link :to="criteron_link()"><DatabaseOutlined />Criterion</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0" key="m4">
		        <router-link :to="scenarios_link()"><BranchesOutlined />Scenarios</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0 && nav.name == 'SgmtnMatrix'" key="m5">
		        <router-link :to="sgmntn_matrix_link()"><SlidersOutlined />Segmentation matrix</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0 && nav.name == 'AnsoffMatrix'" key="m6">
		        <router-link :to="ansff_matrix_link()"><DotChartOutlined />Ansoff matrix</router-link>
		      </a-menu-item>
		      <a-menu-item v-if="nav.curr_model_id != 0 && nav.name == 'Market history'" key="m8">
		        <router-link :to="market_history_link()"><DatabaseOutlined />Market history</router-link>
		      </a-menu-item>
		    </a-menu>
		</a-tab-pane>
		<a-tab-pane key="2" tab="Process" force-render>
			<div style="display: flex; text-align: left;">
				<a-steps
					direction="vertical"
					size="small"
					v-model:current=nav.current
					:items="nav.items"
					style="max-width: 50px; margin: 2px;"
					@change="onChange"
				>
				</a-steps>
				<div style="display: block; width: 100%;" v-if="!nav.loading">
					<a-badge-ribbon :text="nav.items[nav.current].style.text" :color="nav.items[nav.current].style.color">
						<a-card size="small">
							<template #title>
								<div>{{nav.titles[nav.current]}}</div>
							</template>
							<a-row>
								<a-progress 
									size="small" 
									:percent="nav.items[nav.current].percent" 
									status="active"
									style="width: 50%;" />
								<a><CommentOutlined/></a>
							</a-row>
							<a-row>
								<p>Hac ex causa conlaticia stipe Valerius humatur ille Publicola et subsidiis amicorum mariti inops cum liberis uxor alitur Reguli et dotatur ex aerario filia Scipionis.</p>
							</a-row>
							<a-divider style="height: 2px;" />
							<a-row>
								<a-col :span="8">
									<a-tooltip placement="top">
										<template #title>
											<span>Previous step</span>
										</template>
										<a @click="previousStep"><StepBackwardOutlined /></a>
									</a-tooltip>
								</a-col>
								<a-col :span="8">
									<a-divider type="vertical" style="height: 15px;" />
									<a-tooltip placement="top">
										<template #title>
											<span>Add tag</span>
										</template>
										<!--<PopAddItemByName :meta="undefined" v-on:new-item="emitAddEvent" icon="TagOutlined"/>-->
										<a-dropdown>
      										<a><TagOutlined /></a>
      										<template #overlay>
												<a-menu >
										          <a-menu-item key="1" @click="tagInprogress">
										            <EditOutlined />
										            In progress
										          </a-menu-item>
										          <a-menu-item key="2" @click="tagFinish">
										            <CheckOutlined />
										            Finish
										          </a-menu-item>
										          <a-menu-item key="3" @click="tagStandBy">
										            <QuestionOutlined />
										            Stand By
										          </a-menu-item>
										        </a-menu>
									        </template>
    									</a-dropdown>
									</a-tooltip>
									<a-divider type="vertical" style="height: 15px;" />
								</a-col>
    							<a-col :span="8"  style="display: block;text-align: right;">
    								<a-tooltip placement="top">
										<template #title>
											<span>Next step</span>
										</template>
										<a @click="nextStep"><StepForwardOutlined /></a>
									</a-tooltip>
    							</a-col>
							</a-row>
						</a-card>
					</a-badge-ribbon>
				</div>
			</div>
		</a-tab-pane>
	</a-tabs>
    <!-- / Sidebar Navigation Menu -->
    <!-- Sidebar Footer -->
    <!--
			<div class="aside-footer">
				<div class="footer-box">
					<a-button type="primary" href="https://demos.creative-tim.com/muse-vue-ant-design-dashboard/documentation" block>
						Sign out
					</a-button>
				</div>
			</div>
			-->
    <!-- / Sidebar Footer -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
import { reactive, h } from 'vue'
import {
  DatabaseOutlined,
  SlidersOutlined,
  DotChartOutlined,
  BranchesOutlined,
  CommentOutlined,
  TagOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
  ControlOutlined,
  SettingOutlined,
  TableOutlined,
  ClusterOutlined,
  FundViewOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  CheckOutlined,
  QuestionOutlined
} from '@ant-design/icons-vue'
import IconObjectives from '../../components/icons/IconObjectives'
import IconSMatrix from '../../components/icons/IconSMatrix'
import IconAMatrix from '../../components/icons/IconAMatrix'
import IconCompetitor from '../../components/icons/IconCompetitor'
import IconData from '../../components/icons/IconData'
import IconAnalytic from '../../components/icons/IconAnalytic'
import IconImport from '../../components/icons/IconImport'
import IconMS from '../../components/icons/IconMS'
import PopAddItemByName from '../../components/Popovers/PopAddItemByName'


const nav = reactive({ curr_model_id: 0, name: '', items: [] , refreshCount: 0, titles: [], loading: true, progress: 0})
const api = {}
const steps = {
	'step_1': {title:"Economic objectives", icon: h(IconMS, {img:'1'})}, 
	'step_2': {title:"Segmentation Matrix", icon: h(IconMS, {img:'3'})}, 
	'step_3': {title:"Ansoff Matrix", icon: h(IconMS, {img:'4'})},
	'step_4': {title:"Competitor", icon: h(IconMS, {img:'5'})}, 
	'step_5': {title:"Data", icon: h(IconMS, {img:'6'})},
	'step_6': {title:"Analytics", icon: h(IconMS, {img:'7'})}, 
	'step_7': {title:"import & export", icon: h(IconMS, {img:'11'})}}
const step_status = {
	's_0': {value: "wait", text:"Wait", color:"yellow"}, 
	's_1': {value: "process", text:"In progress", color:"geekblue"}, 
	's_2': {value: "finish", text:"Done", color:"green"},
	's_3': {value: "error", text:"Stand by", color:"magenta"}}
const callbacks = { }


export default {
  components: {
    DatabaseOutlined,
    SlidersOutlined,
    DotChartOutlined,
    BranchesOutlined,
    CommentOutlined,
    TagOutlined,
    StepForwardOutlined,
    StepBackwardOutlined,
    PopAddItemByName,
    ControlOutlined,
    SettingOutlined,
    TableOutlined,
    ClusterOutlined,
    FundViewOutlined,
    CloudDownloadOutlined,
    EditOutlined,
  	CheckOutlined,
  	QuestionOutlined,
  	IconObjectives,
  	IconSMatrix,
  	IconAMatrix,
  	IconData,
  	IconAnalytic,
  	IconImport,
  	IconMS
  },
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: 'primary'
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: 'light'
    }
  },
  setup() {
  	const navMode = reactive({activeKey:"2"})
    return {
      nav, navMode, steps, callbacks
    }
  },
  created() {
    this.init()
    this.fetch_process_instance()
    //this.callbacks['step_1'] = this.current_model_link;
    this.callbacks['step_1'] = this.economic_obj_link;
    this.callbacks['step_2'] = this.sgmtn_matrices_link;
    this.callbacks['step_3'] = this.ansoff_matrices_link;
    this.callbacks['step_4'] = this.competitors_link;
    this.callbacks['step_5'] = this.market_attractiveness_link;
    this.callbacks['step_6'] = this.analytics_link;
    this.callbacks['step_7'] = this.economic_obj_link;
  },
  methods: {
    init: function () {
      if (this.$route.params.model_id) {
        nav.name = this.$route.name
        nav.curr_model_id = this.$route.params.model_id
      }
      api.url = process.env.VUE_APP_MAIN_API_ENDPOINT + '/api/model/' + this.$route.params.model_id
      api.mixurl = process.env.VUE_APP_MIX_API_ENDPOINT + '/api/model/' + this.$route.params.model_id
    },
    models_link: function () {
      return { path: '/user/' + window.localStorage.getItem('user_id') + '/models' }
    },
    current_model_link: function () {
      return { path: '/model/' + this.$route.params.model_id}
    },
    products_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/products' }
    },
    competitors_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/competitors' }
    },
    scenarios_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/scenarios' }
    },
    sgmntn_matrix_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/sgmntn-matrix' }
    },
    ansff_matrix_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/ansff-matrix' }
    },
    economic_obj_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/objective-economics' }
    },
    analytics_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/analytics' }
    }, 
    market_history_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/market-history' }
    },
    market_attractiveness_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/market_attractiveness' }
    },
    competitive_strength_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/competitive_strength' }
    },
    criteron_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/criteron' }
    },
    sgmtn_matrices_link: function(){
    	return { path: '/model/' + this.$route.params.model_id + '/sgmtn-matrices' }
    },
    ansoff_matrices_link: function(){
    	return { path: '/model/' + this.$route.params.model_id + '/ansoff-matrices' }
    },
    emitAddEvent(){

    },
    fetch_process_instance(){
    	console.log("Process instance", nav.refreshCount++)
    	this.nav.loading = true
    	if(nav.refreshCount<2){
	    	this.api_get(api.mixurl + '/process').then(json => {
	    		this.nav.titles = []
	    		this.nav.items = []
	    		json.steps.forEach(step => {
	    			steps[step.key].icon.props.color = step_status['s_'+step.status].color
	    			steps[step.key].icon.props.percent = step.percent
	    			this.nav.titles.push(steps[step.key].title)
	    			this.nav.items.push({
	    				key: step.key,
	    				title: "",
	    				//status: step_status['s_'+step.status].value,
	    				style: step_status['s_'+step.status],
	    				icon: steps[step.key].icon,
	    				percent: step.percent
	    			})
	    		})
	    		this.nav.current = json.current;
	    		this.nav.loading = false
	    	})
    	}
    },
    nextStep(){
    	this.nav.current++
    	this.onChange()
    },
    previousStep(){
    	this.nav.current--
    	this.onChange()
    },
    onChange(){
    	const next_key = this.nav.items[this.nav.current].key
    	this.nav.progress = this.nav.items[this.nav.current].percent
    	if( next_key != undefined && this.callbacks[next_key] != undefined){
    		this.$store.commit('nextStep', next_key)
    		this.$store.dispatch('timeline_loading')
    		const next_route = this.callbacks[next_key]()
    		if( next_route != undefined){
    			this.$router.push(next_route)
    		}
    	}
    },
    tagInprogress(){
    	const next_key = this.nav.items[this.nav.current].key
    	const user_id = window.localStorage.getItem('user_id')
    	this.api_update(api.mixurl + "/taginprogress", {key: next_key, id_user: user_id}).then(json => {
    		//todo
    		this.nav.refreshCount = 0
    		this.fetch_process_instance()
    	})
    	this.$store.dispatch('timeline_loading')
    },
    tagFinish(){
    	const next_key = this.nav.items[this.nav.current].key
    	const user_id = window.localStorage.getItem('user_id')
    	this.api_update(api.mixurl + "/tagfinish",{key: next_key, id_user: user_id}).then(json => {
    		//todo
    		this.nav.refreshCount = 0
    		this.fetch_process_instance()
    	})
    	this.$store.dispatch('timeline_loading')
    },
    tagStandBy(){
    	const next_key = this.nav.items[this.nav.current].key
    	const user_id = window.localStorage.getItem('user_id')
    	this.api_update(api.mixurl + "/tagstandby",{key: next_key, id_user: user_id}).then(json => {
    		//todo
    		this.nav.refreshCount = 0
    		this.fetch_process_instance()
    	})
    	this.$store.dispatch('timeline_loading')
    }
  }
}
</script>
<style scoped>
.ant-menu-submenu-title {
  padding-left: 0px !important;
}
.ant-scroll-number.ant-badge-count{
	margin-left: 30px;
}
.brand img{
	height: 50% !important;
	width: 100% !important;
	margin-top: -30% !important;
	margin-bottom: -30% !important;
}
</style>
