<template>
<div class="icon-container">
	<span class="icon">
		<img :src='"/images/icons/"+img+".png"'/>
	</span>
	<a-progress class="prog" type="circle" :percent="percent" :size="30" :show-info="false" :stroke-color="color"/>
</div>
</template>
<script>
export default {
	props:{
		img:{
			type:String,
			default: '1'
		},
		percent:{
			type: Number,
			default: 0
		},
		color:{
			type:String,
			default: '#000000'
		}
	},
	components: {},
	created(){
		console.log("Icon",this.$store.getters.getCurrentStep);
	}
}
</script>
<style lang="scss" scoped>
.icon-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(235, 124, 4, 1);
    --box-shadow: 0 4px 8px v-bind(color);
}

.icon-container .icon {
    font-size: 20px;
}
.icon-container .icon img{
	width: 16px;
    height: 16px;
    margin-left: 6px;
	margin-top: -4px;	
}
.icon-container .prog {
	font-size: 20px;
	margin-left: -22px;
	margin-top: -1px;
}
</style>