//import './assets/main.css';
import Antd from 'ant-design-vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import ModelLayout from './layouts/Model.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import 'ant-design-vue/dist/reset.css'
import './scss/app.scss'
import numeral from 'numeral'

import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'


const api_update = function (url, body, callback) {
  const params = { method: 'POST', headers: { 'content-type': 'application/json' } }
  if (body != null) {
    params['body'] = JSON.stringify(body)
  }
  return fetch(url, params).then((res) => {
    if (res.ok) return res.json()
  })
}
const api_get = function (url, body, callback) {
  const params = { method: 'GET', headers: { 'content-type': 'application/json' } }
  return fetch(url, params).then((res) => {
    if (res.ok) return res.json()
  })
}

const app = createApp(App)
app.component('layout-default', DefaultLayout)
app.component('layout-dashboard', DashboardLayout)
app.component('layout-model', ModelLayout)
app.component('layout-dashboard-rtl', DashboardRTLLayout)

app.use(Antd)
app.use(router)
app.use(store)

app.config.globalProperties.api_update = function (url, body, callback) {
  const params = { method: 'POST', headers: { 'content-type': 'application/json' } }
  if (body != null) {
    params['body'] = JSON.stringify(body)
  }
  return fetch(url, params).then((res) => {
    if (res.ok) return res.json()
  })
}
app.config.globalProperties.api_get = function (url, callback) {
  const params = { method: 'GET', headers: { 'content-type': 'application/json' } }
  return fetch(url, params).then((res) => {
    if (res.ok) return res.json()
  })
}
app.config.globalProperties.formatNumber = function (value) {
  return numeral(value).format('0,0.00')
}

app.mount('#app')
