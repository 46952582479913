import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

//const main_user_id = window.localStorage.getItem('user_id')
//const main_token = window.localStorage.getItem('_token')
const check_session_func = function (to, from, next) {
  if (window.localStorage.getItem('user_id') == undefined || window.localStorage.getItem('_token') == null) {
    router.push({ path: '/sign-in' })
  }
  next()
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      //component: HomeView
      component: () => import('../views/Sign-In.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/sign-in',
      name: 'Sign-In',
      component: () => import('../views/Sign-In.vue')
    },
    { 
      path: '/log-out', 
      name: 'Logout', 
      component: () => import('../views/Log-Out.vue') 
    },
    {
      path: '/sign-up',
      name: 'Sign-Up',
      meta: {
        layoutClass: 'layout-sign-up'
      },
      component: () => import('../views/Sign-Up-Test.vue')
    },
    {
      path: '/reset-password',
      name: 'Reset-Password',
      meta: {
        layoutClass: 'layout-sign-up'
      },
      component: () => import('../views/Reset-Pass.vue')
    },
    {
      path: '/profile/:user_id',
      name: 'Profile',
      meta: {
        layout: 'dashboard',
        layoutClass: 'layout-profile'
      },
      component: () => import('../views/Profile.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/user/:user_id/models',
      name: 'Models',
      meta: {
        layout: 'dashboard',
        layoutClass: 'layout-profile'
      },
      component: () => import('../views/Models.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/team/:user_id/user/:member_id',
      name: 'MemberProfile',
      meta: {
        layout: 'dashboard',
        layoutClass: 'layout-profile'
      },
      component: () => import('../views/MemberProfile.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id',
      name: 'Model config',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/Model.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenarios',
      name: 'Scenarios',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/Scenarios.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/sgmtn_matrix',
      name: 'SgmtnMatrix',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/SegmentationMatrix.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/sgmtn-matrices',
      name: 'Segmentation matrices',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/SegmentationMatrices.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/ansoff-matrices',
      name: 'Ansoff matrices',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/AnsoffMatrices.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/ansoff_matrix',
      name: 'Ansoff matrice',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/AnsoffMatrix.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/analytics',
      name: 'Analysis',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/Analytics.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/products',
      name: 'Products',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/Products.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/competitors',
      name: 'Competitors',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/Competitors.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/objective-economics',
      name: 'Data',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/DataEconomicObjectives.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/market-history',
      name: 'Market history',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/DataMarketHistory.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/market-history',
      name: 'Market history',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/DataMarketHistory.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/market_attractiveness',
      name: 'Market attractiveness Factor',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/DataMarketAttractiveness.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/market_attractiveness',
      name: 'Market attractiveness',
      meta: {
        layout: 'model',
        layoutClass: 'layout-model'
      },
      component: () => import('../views/DataMarketAttractivenessScores.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/competitive_strength',
      name: 'Competitive strength factor',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/DataCompetitiveStrength.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/competitive_strength',
      name: 'Competitive strength',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/DataCompetitiveStrengthScores.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/life-cycle-analysis',
      name: 'Life cycle analysis menu',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/LifeCycleAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/life-cycle-analysis',
      name: 'Life cycle analysis menu / scenario',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/LifeCycleAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/life-cycle-analysis',
      name: 'Life cycle analysis menu / market',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/LifeCycleAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/life-cycle-analysis',
      name: 'Life cycle analysis',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/LifeCycleAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/lca-:data_index',
      name: 'Chart Life Cycle Analysis',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/AnalysisT6.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/sa-:data_index',
      name: 'Chart share analysis',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/AnalysisT11.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/sa-:data_index',
      name: 'Chart share analysis-',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/AnalysisT11.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/sa-:data_index',
      name: 'Market share analysis',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/AnalysisT11.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/product/:product_id/sa-:data_index',
      name: 'Product share analysis',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/AnalysisT11.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/share-analysis',
      name: 'Share analysis (model)',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/ShareAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/share-analysis',
      name: 'Share analysis (scenario)',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/ShareAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    },
    {
      path: '/model/:model_id/scenario/:scenario_id/market/:market_id/couple/:couple_id/share-analysis',
      name: 'Share analysis (couple)',
      meta: {
        layoutClass: 'layout-model',
        layout: 'model'
      },
      component: () => import('../views/ShareAnalysis.vue'),
      beforeEnter: (to, from, next) => {
        check_session_func(to, from, next)
      }
    }
  ]
})

export default router
