<template>
<img src="/images/icons/segmentationmatric.svg" style="width:40px;height:40px;"/>
<!-- 3.png -->
</template>
<script>
export default {
	created(){
		console.log("Icon",this.$props.current);

	}
}
</script>