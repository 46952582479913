<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
  <div>
    <!-- Dashboard Layout -->
    <a-layout
      class="layout-model"
      id="layout-model"
      :class="[
        navbarFixed ? 'navbar-fixed' : '',
        !sidebarCollapsed ? 'has-sidebar' : '',
        layoutClass
      ]"
    >
      <!-- Main Sidebar -->
      <ModelSidebar
        :sidebarCollapsed="sidebarCollapsed"
        :sidebarColor="sidebarColor"
        :sidebarTheme="sidebarTheme"
        @toggleSidebar="toggleSidebar"
      ></ModelSidebar>
      <!-- / Main Sidebar -->

      <!-- Layout Content -->
      <a-layout>
        <!-- Layout Header's Conditionally Fixed Wrapper -->
        <ModelHeader
          :sidebarCollapsed="sidebarCollapsed"
          :navbarFixed="navbarFixed"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          @toggleSidebar="toggleSidebar"
        ></ModelHeader>
        <!-- / Layout Header's Conditionally Fixed Wrapper -->

        <a-layout>
            
            <!-- Page Content -->
            <a-layout-content>
              <router-view />
            </a-layout-content>
            <!-- / Page Content -->

            <!-- Main steps timeline -->
            <ModelStepTimeline />
            <!-- / Main steps timeline -->

        </a-layout>
            


        <!-- Layout Footer -->
        <ModelFooter></ModelFooter>
        <!-- / Layout Footer -->

        <!-- Floating Action Button For Toggling Settings Drawer -->
        <a-float-button shape="circle" :badge="{ dot: true }" :style="{ right: '10px', top: '45%' }" >
          <template #icon>
            <TeamOutlined />
          </template>
        </a-float-button>
        <!-- / Floating Action Button For Toggling Settings Drawer -->

        <!-- Sidebar Overlay -->
        <div
          class="sidebar-overlay"
          @click="sidebarCollapsed = true"
          v-show="!sidebarCollapsed"
        ></div>
        <!-- / Sidebar Overlay -->
      </a-layout>
      <!-- / Layout Content -->

      <!-- Settings Drawer -->
      <DashboardSettingsDrawer
        :showSettingsDrawer="showSettingsDrawer"
        :navbarFixed="navbarFixed"
        :sidebarTheme="sidebarTheme"
        @toggleSettingsDrawer="toggleSettingsDrawer"
        @toggleNavbarPosition="toggleNavbarPosition"
        @updateSidebarTheme="updateSidebarTheme"
        @updateSidebarColor="updateSidebarColor"
      ></DashboardSettingsDrawer>
      <!-- / Settings Drawer -->
    </a-layout>
    <!-- / Dashboard Layout -->
  </div>
</template>

<script>
import ModelSidebar from '../components/Sidebars/ModelSidebar'
import ModelStepTimeline from '../components/Sidebars/ModelStepTimeline'
import ModelHeader from '../components/Headers/ModelHeader'
import ModelFooter from '../components/Footers/ModelFooter'
import DashboardSettingsDrawer from '../components/Sidebars/DashboardSettingsDrawer'
import {TeamOutlined} from '@ant-design/icons-vue'

export default {
  components: {
    ModelSidebar,
    ModelHeader,
    ModelFooter,
    DashboardSettingsDrawer,
    TeamOutlined,
    ModelStepTimeline
  },
  setup() {
    return {
      // Sidebar collapsed status.
      sidebarCollapsed: false,

      // Main sidebar color.
      sidebarColor: 'primary',

      // Main sidebar theme : light, white, dark.
      sidebarTheme: 'light',

      // Header fixed status.
      navbarFixed: false,

      // Settings drawer visiblility status.
      showSettingsDrawer: false
    }
  },
  methods: {
    toggleSidebar(value) {
      this.sidebarCollapsed = value
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value
    },
    updateSidebarColor(value) {
      this.sidebarColor = value
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass
    }
  }
}
</script>
