// store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    notif_count: 0,
    step: 'step_1',
    tag: 0,
    display_comment_box: false,
    loading: {
     _timeline: true,
     _coments: true
    }
  },
  mutations: {
    nextStep(state, key){
      state.step = key
    },
    updateLoadingStatus(state, status){
      state.loading._timeline = status
    },
    opentTag(state, tag){
      state.tag = tag
    },
    updateCommentsStatus(state, status){
      state.loading._coments = status
    },
    swhitch_display_comment_box(state, status){
      state.display_comment_box = status
    },
    updateNotifCount(state, value){
      state.state = state.state + value
    }
  },
  actions: {
    timeline_loading(context){
      context.commit('updateLoadingStatus', true)
    },
    timeline_loading_finish(context){
      context.commit('updateLoadingStatus', false)
    },
    comments_loading(context){
      context.commit('updateCommentsStatus', true)
    },
    comments_loading_finish(context){
      context.commit('updateCommentsStatus', false)
    }
  },
  getters: {
    getCurrentStep(state){
      return state.step
    },
    getCurrentTag(state){
      return state.tag;
    },
    isLoadingTimeline(state){
      return state.loading._timeline;
    },
    isLoadingComments(state){
      return state.loading._coments;
    },
    isDisplayCommentBox(state){
      return state.display_comment_box
    }
  },
});
