<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img src="/images/strategy-studio.png" alt="Strategic studio" style="width: 100%; height: 100%;" />
    </div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline">
      <a-menu-item>
        <router-link :to="profile_link()">
          <span class="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
                fill="#111827"
              />
            </svg>
          </span>
          <span class="label">Profile</span>
        </router-link>
      </a-menu-item>
      <a-menu-item class="menu-item-header" key="m1">
        <router-link :to="models_link()">MODELS</router-link>
      </a-menu-item>
      <!--
				<a-menu-item class="menu-item-header">
					Account
				</a-menu-item>
				-->
      <!--
				<a-menu-item>
					<router-link to="/sign-in">
						<span class="icon">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z" fill="#111827"/>
							</svg>
						</span>
						<span class="label">Sign In</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/sign-up">
						<span class="icon">
							<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<title>duplicate</title>
								<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="Tables" transform="translate(-58.000000, -507.000000)" fill="#BFBFBF" fill-rule="nonzero">
										<g id="sidebar" transform="translate(33.000000, 43.000000)">
											<g id="sign-up" transform="translate(16.000000, 455.000000)">
												<g id="duplicate" transform="translate(9.000000, 9.000000)">
													<path d="M4,6 C4,4.89543 4.89543,4 6,4 L12,4 C13.1046,4 14,4.89543 14,6 L14,12 C14,13.1046 13.1046,14 12,14 L6,14 C4.89543,14 4,13.1046 4,12 L4,6 Z" id="Path"></path>
													<path d="M2,0 C0.89543,0 0,0.89543 0,2 L0,8 C0,9.1046 0.89543,10 2,10 L2,4 C2,2.8954305 2.8954305,2 4,2 L10,2 L10,2 C10,0.89543 9.1046,0 8,0 L2,0 Z" id="Path"></path>
												</g>
											</g>
										</g>
									</g>
								</g>
							</svg>
						</span>
						<span class="label">Sign Up</span>
					</router-link>
				</a-menu-item>
				-->
    </a-menu>
    <!-- / Sidebar Navigation Menu -->

    <!-- Sidebar Footer -->
    <!--
			<div class="aside-footer">
				<div class="footer-box">
					<a-button type="primary" href="https://demos.creative-tim.com/muse-vue-ant-design-dashboard/documentation" block>
						Sign out
					</a-button>
				</div>
			</div>
			-->
    <!-- / Sidebar Footer -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
const nav = { curr_model_id: 0 }

export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: 'primary'
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: 'light'
    }
  },
  setup() {
    return {
      nav
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function () {
      if (this.$route.params.model_id) nav.curr_model_id = this.$route.params.model_id
      //api.url = "http://127.0.0.1:8000/api/user/"+this.$route.params.user_id;
    },
    profile_link: function () {
      return { path: '/profile/' + this.$route.params.user_id }
    },
    models_link: function () {
      return { path: '/user/' + this.$route.params.user_id + '/models' }
    },
    competitors_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/competitors' }
    },
    scenarios_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/scenarios' }
    },
    sgmntn_matrix_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/sgmntn-matrix' }
    },
    ansff_matrix_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/ansff-matrix' }
    },
    economic_obj_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/objective-economics' }
    },
    market_history_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/market-history' }
    },
    market_attractiveness_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/market_attractiveness' }
    },
    competitive_strength_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/competitive_strength' }
    },
    criteron_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/criteron' }
    }
  }
}
</script>
<style scoped>
.ant-menu-submenu-title {
  padding-left: 0px !important;
}
</style>
