<template>
  <!-- Add Criterion Popover -->
  <a-popover trigger="click" placement="bottomRight">
    <a v-if="icon==='TagOutlined'"><TagOutlined /></a>
    <a v-else><PlusOutlined /></a>
    <template #content>
      <a-form
        ref="formRef"
        name="custom-validation"
        size="small"
        :model="formState"
        layout="vertical"
        @finish="emitAddEvent"
      >
        <a-form-item
          has-feedback
          name="name"
          :rules="[{ required: true, message: 'Please input name!' }]"
        >
          <a-input v-model:value="formState.name" autocomplete="off" placeholder="Input name" />
        </a-form-item>
        <a-form-item style="text-align: right">
          <a-button type="primary" html-type="submit"><SaveOutlined />Add</a-button>
        </a-form-item>
      </a-form>
    </template>
  </a-popover>
  <!-- / Add Criterion Popover -->
</template>

<script>
import { ref, reactive } from 'vue'
import { PlusOutlined, SaveOutlined, TagOutlined } from '@ant-design/icons-vue'

export default {
  components: { PlusOutlined, SaveOutlined, TagOutlined },
  props: {
    icon:{
      type: String,
      default: 'PlusOutlined'
    },
    meta: {
      type: Object,
      default: null
    }
  },
  setup() {
    const formState = reactive({ name: '' })
    return { formState }
  },
  methods: {
    emitAddEvent: function () {
      this.$emit('new-item', this.meta, this.formState.name)
    }
  }
}
</script>

<style scoped></style>
