<template>
  <!-- Active Users Card -->
  <div class="card-container">
    <a-card :loading="loading" size="small">
      <template #title>
        <h6 class="font-semibold m-0" v-if="users==undefined || data.step == undefined || data.tag == undefined">#None</h6>
        <h6 class="font-semibold m-0" v-else>
          <TagOutlined :style="{color: tag_color(data.tag)}" /> {{data.step.key.toUpperCase()}} tagged {{data.tag.name.toLowerCase()}} by {{username(data.tag.id_user)}}
        </h6>
      </template>
      <template #extra><a @click="closeCard()"><CloseCircleOutlined /></a></template>
      <a-row class="conversations-box">
        <a-col :span="48" v-if="!$store.getters.isLoadingComments">
          <a-list class="conversations-list" item-layout="horizontal" :split="false" :data-source="data.comments" size="small">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta>
                  <template #title>
                    <a >{{ username(item.id_user) }}</a>
                  </template>
                  <template #avatar>
                    <a-avatar :key="item.id_user" size="small">{{ srt_username(item.id_user) }}</a-avatar>
                  </template>
                  <template #description>
                    <p>{{item.comment}}</p>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </a-col>
        <p v-else>{{fetch_conversations()}}</p>
      </a-row>
      <a-form
        :model="formState"
        name="conversations_form"
        class="login-form"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item name="message" :rules="[{ required: true, message: 'Please input your comment' }]">
              <a-textarea
                v-model:value="formState.message"
                placeholder="Input your comment here"
                :auto-size="{ minRows: 2, maxRows: 5 }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" style="text-align: right;">
            <a-button type="primary" @click="sendComment" html-type="submit" :disabled="formState.message==''">
              <template #icon><SendOutlined /></template>
              Send comment
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </div>
  <!-- Active Users Card -->
</template>

<script>
import {reactive} from 'vue'
import {SendOutlined, CloseCircleOutlined, TagOutlined} from '@ant-design/icons-vue'

const api = {}
const tag_colors = {
  'InProgress':'#108ee9',
  'StandBy':'#eb2f96',
  'Done':'#87d068'
}

export default {
  components: {
    SendOutlined, CloseCircleOutlined, TagOutlined
  },
  props: {
    meta: {
      type: Object,
      default: undefined
    },
    users:{
      type: Object,
      default: undefined
    }
  },
  setup() {
    const data = reactive({step:null, tag:null, comments: []})
    const formState = reactive({message: ""})
    return {
      data, formState
    }
  },
  created(){
    this.init()
  },
  methods:{
    init: function () {
      api.url = process.env.VUE_APP_MAIN_API_ENDPOINT + '/api/model/' + this.$route.params.model_id
      api.mixurl = process.env.VUE_APP_MIX_API_ENDPOINT + '/api/model/' + this.$route.params.model_id
    },
    tag_color(tag){
      if(tag!=undefined)
        return tag_colors[tag.name];
      return '#87d068'
    },
    fetch_conversations(){
      const tag_id = this.$store.getters.getCurrentTag
      this.api_get(api.mixurl+"/tag/"+tag_id+"/getcomments").then(json => {
        this.data.step = json.step
        this.data.tag = json.tag
        this.data.comments = json.data
        this.$store.dispatch("comments_loading_finish")
      })
      return "Loading ...";
    },
    onFinish(){
      const tag_id = this.$store.getters.getCurrentTag
      const id_user = window.localStorage.getItem('user_id')
      this.api_update(api.mixurl+"/tag/"+tag_id+"/addcomment",{comment: this.formState.message, id_user: id_user}).then(json => {
        if(json.data=null){
          this.data.comments.push(json.data)
        }
        this.formState.message = ""
        this.$store.dispatch("timeline_loading")
        this.$store.dispatch("comments_loading")
      })
    },
    onFinishFailed(e){
      console.log(e)
    },
    closeCard(){
      this.$emit('close-conversation-box')
    },
    canSubmit(){
      return (this.data.step != undefined) 
        && (this.$route.params.model_id == this.data.step.id_model) 
        && (this.formState.message != "")
    },
    username(userid){
      return userid && this.users['u_'+userid] ? this.users['u_'+userid].name: "#"+userid
    },
    srt_username(userid){
      return this.username(userid).substring(0,2)
    }
  }
}
</script>
<style scoped>
.card-container{
  width: 450px;
  margin-left: -300px;
}
.conversations-box{
  max-height: 250px;
  overflow-y: scroll;
}
li.ant-list-item{
  width: 400px;
  padding: 0px 0px;
}
li.ant-list-item p{
  font-size: 14px;
  font-weight: 100;
}

</style>
