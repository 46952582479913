<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    width="150px"
    theme="light"
    :style="{ backgroundColor: 'transparent'}"
  >
  	<a-affix :offset-bottom="top">
  	<div style="padding-right: 5px;">
	  	<a-timeline v-if="!$store.getters.isLoadingTimeline" mode="right">
				<a-timeline-item v-for="tag in data.tags" :color="tag_color(tag)">
			  	<a-row>
			  		<a-col :span="24" class="step-tag">
				      <a-badge 
				  			:count="tag.meta.count"
				  			:number-style="{
				  				opacity: 0.5,
						      backgroundColor: '#fff',
						      color: '#999',
						      boxShadow: '0 0 0 1px #d9d9d9 inset',
						    }">
						    <a-tooltip placement="top">
					        <template #title>
					          <span>Open conversation</span>
					        </template>
					        <a @click="openConversationsBox(tag)"><CommentOutlined /></a>
					      </a-tooltip>
						    <p>{{tag.name}}</p>
						  </a-badge>
					  </a-col>
					  <a-col :span="24" class="user-avatars">
					  	<a-avatar-group>
					  		<a-tooltip  v-for="(img, index) in tag.meta.users" :title="username(img)" placement="top">
					  			<a-avatar size="small" :key="index">{{srt_username(img)}}</a-avatar>
				  			</a-tooltip>
				  		</a-avatar-group>
			      </a-col>
		  		</a-row>
			  	<template #dot>
			  		<div style="display: flex;">
			  			<TagOutlined />
			  		</div>
	      	</template>
		  	</a-timeline-item>
		  </a-timeline>
	  	<div v-else>{{fetch_timeline()}}</div>
	  </div>
	  </a-affix>

	  <a-affix :offset-bottom="bottom" :offset-top="top">
      <CardComment 
      	:users=data.users
      	v-if="$store.getters.isDisplayCommentBox"
      	v-on:close-conversation-box="closeConversationsBox"/>
    </a-affix>
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
import { ref, reactive, h } from 'vue'
import {
	SmileOutlined,

  DatabaseOutlined,
  SlidersOutlined,
  DotChartOutlined,
  BranchesOutlined,
  CommentOutlined,
  TagOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
  ControlOutlined,
  SettingOutlined,
  TableOutlined,
  ClusterOutlined,
  FundViewOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  CheckOutlined,
  QuestionOutlined,
  MessageOutlined
} from '@ant-design/icons-vue'
import IconObjectives from '../../components/icons/IconObjectives'
import PopAddItemByName from '../../components/Popovers/PopAddItemByName'
import CardComment from '../../components/Cards/CardComment'

const nav = reactive({ curr_model_id: 0, name: '', items: [] , refreshCount: 0, titles: [], loading: true, progress: 0})
const api = {}
const steps = {
	'step_1': {title:"Specification"}, 
	'step_2': {title:"Economic objectives"}, 
	'step_3': {title:"Matrix", icon: h(TableOutlined)},
	'step_4': {title:"Competitor", icon: h(ClusterOutlined)}, 
	'step_5': {title:"Data", icon: h(DatabaseOutlined)},
	'step_6': {title:"Analytics", icon: h(FundViewOutlined)}, 
	'step_7': {title:"import & export", icon: h(CloudDownloadOutlined)}}
const step_status = {
	's_0': {value: "wait", text:"Wait", color:"yellow"}, 
	's_1': {value: "process", text:"In progress", color:"geekblue"}, 
	's_2': {value: "finish", text:"Done", color:"green"},
	's_3': {value: "error", text:"Stand by", color:"magenta"}}
const callbacks = { }
const tag_colors = {
	'InProgress':'#108ee9',
	'StandBy':'#eb2f96',
	'Done':'#87d068'
}
const route_steps = {
	"Model config": 'step_1',
	"Data": 'step_2',
	"Scenarios": 'step_3',
	"Competitors": 'step_4',
	"Market attractiveness Factor": 'step_5',
}

const team = [
	'img/img001.jpg',
	'img/img002.jpg',
	'img/img003.jpg',
	'img/img004.jpg',
]

const top = ref(10);
const bottom = ref(10);


export default {
  components: {
  	SmileOutlined,

    DatabaseOutlined,
    SlidersOutlined,
    DotChartOutlined,
    BranchesOutlined,
    CommentOutlined,
    TagOutlined,
    StepForwardOutlined,
    StepBackwardOutlined,
    PopAddItemByName,
    ControlOutlined,
    SettingOutlined,
    TableOutlined,
    ClusterOutlined,
    FundViewOutlined,
    CloudDownloadOutlined,
    EditOutlined,
  	CheckOutlined,
  	QuestionOutlined,
  	MessageOutlined,

  	CardComment,
  	IconObjectives
  },
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: 'primary'
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: 'light'
    }
  },
  setup() {
  	const navMode = reactive({activeKey:"2"})
  	const loading = reactive({tags: true})
  	const data = {tags:[], users:{}}
    return {
      loading, data, team, top, bottom
    }
  },
  created() {
    this.init()
    //this.fetch_timeline()
    /**
    this.fetch_process_instance()
    this.callbacks['step_1'] = this.current_model_link;
    this.callbacks['step_2'] = this.economic_obj_link;
    this.callbacks['step_3'] = this.scenarios_link;
    this.callbacks['step_4'] = this.competitors_link;
    this.callbacks['step_5'] = this.market_attractiveness_link;
    this.callbacks['step_6'] = this.economic_obj_link;
    this.callbacks['step_7'] = this.economic_obj_link;
    */
  },
  methods: {
    init: function () {
      if (this.$route.params.model_id) {
        nav.name = this.$route.name
        nav.curr_model_id = this.$route.params.model_id
      }
      const user_id = window.localStorage.getItem('user_id')
      api.url = process.env.VUE_APP_MAIN_API_ENDPOINT + '/api/user/' + user_id
      api.mixurl = process.env.VUE_APP_MIX_API_ENDPOINT + '/api/model/' + this.$route.params.model_id
    },
    models_link: function () {
      return { path: '/user/' + window.localStorage.getItem('user_id') + '/models' }
    },
    current_model_link: function () {
      return { path: '/model/' + this.$route.params.model_id}
    },
    products_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/products' }
    },
    competitors_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/competitors' }
    },
    scenarios_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/scenarios' }
    },
    sgmntn_matrix_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/sgmntn-matrix' }
    },
    ansff_matrix_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/ansff-matrix' }
    },
    economic_obj_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/objective-economics' }
    },
    market_history_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/market-history' }
    },
    market_attractiveness_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/market_attractiveness' }
    },
    competitive_strength_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/competitive_strength' }
    },
    criteron_link: function () {
      return { path: '/model/' + this.$route.params.model_id + '/criteron' }
    },
    emitAddEvent(){

    },
    fetch_timeline(){
    	this.api_get(api.url+"/teammap").then(json => {
    		this.data.users = json.data
	    	this.api_update(api.mixurl+"/timeline", 
	    		{'step': this.$store.getters.getCurrentStep}).then(json => {
	    		this.data.tags = json.data
	    		this.$store.dispatch('timeline_loading_finish')
	    	})
    	})
    	return 'Loading ...'
    },
    openConversationsBox(tag){
    	console.log('opentTag', tag)
    	this.$store.commit('opentTag', tag.id)
    	this.$store.commit('swhitch_display_comment_box', true)
    	this.$store.dispatch('comments_loading')
    },
    closeConversationsBox(){
    	this.$store.commit('swhitch_display_comment_box', false)
    },
    tag_color(tag){
    	if(tag!=undefined)
    		return tag_colors[tag.name];
    	return '#87d068'
    },
    username(userid){
    	return userid && this.data.users['u_'+userid] ? this.data.users['u_'+userid].name: "#"+userid
    },
    srt_username(userid){
    	return this.username(userid).substring(0,2)
    }
  }
}
</script>
<style scoped>
.user-avatars, .step-tag{
	display: block;
	float: right;
}
</style>
